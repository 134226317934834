export const PoweredBy = (): JSX.Element => (
  <div className="flex items-center flex-col bowl-content text-sm">
    <span className="text-sm uppercase font-normal text-typography-white text-opacity-40">
      Powered by
    </span>
    <a href="https://hostaqui.com.br" target="_blank" rel="noreferrer">
      Urbano Norte & Urbano Labs
    </a>
  </div>
);
